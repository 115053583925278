/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Confirm from 'vux/src/components/confirm';
import XButton from 'vux/src/components/x-button';
import TransferDom from 'vux/src/directives/transfer-dom';
export default {
  name: 'LossSolution',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Group: Group,
    XButton: XButton,
    Cell: Cell,
    Confirm: Confirm
  },
  data: function data() {
    return {
      cardInfo: {},
      showRepeal: false,
      //是否显示确认弹窗
      cardstate: 0,
      //卡片状态
      lossnum: '2'
    };
  },
  methods: {
    lossClick: function lossClick(cardstate) {
      if (cardstate != 0) {
        this.showRepeal = true;
      }
    },
    onConfirm: function onConfirm() {
      var _this = this;

      this.$api.My.cardLoss({
        userSerial: this.$utils.Store.getItem('userSerial'),
        userCard: this.cardInfo.userCard
      }).then(function (response) {
        var code = response.code,
            msg = response.msg;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.$utils.Tools.toastShow(msg);

          var _that = _this;
          setTimeout(function () {
            _that.$router.go(-1);
          }, 2000);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    var _this2 = this;

    this.$api.My.getCardInfo({
      userSerial: this.$utils.Store.getItem('userSerial')
    }).then(function (response) {
      var code = response.code,
          msg = response.msg,
          data = response.data;

      _this2.$utils.Tools.loadHide();

      if (+code !== _this2.$code.success) {
        _this2.$utils.Tools.toastShow(msg);
      } else {
        _this2.cardInfo = data;
      }
    }).catch(function () {});
  }
};